@import "assets/scss/style.scss";

.nav-outside.mob-backdrop{
  &.mob-fixed .pcoded-navbar{
    z-index: 1032;
  }
   ~ .pcoded-outside{
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1031;
    background: rgba(0, 0, 0, 0.25);
  }
}
}
.pcoded-header .dropdown .notification .noti-body li {
  width: 100%;
}

.header-chat .h-list-footer .input-group {
  .btn-attach {
    padding: 0;
    width: 35px;
    height: 35px;
  }

  .btn-send {
    top: 7px;
  }
}

.main-search {
  padding: 16px 12px;
}

@media only screen and (max-width: 991px) {
  .nav-outside {
    ~ {
      .pcoded-header, .pcoded-main-container {
        margin-left: 0;
      }
    }

    &.mob-fixed ~ .pcoded-main-container {
      padding-top: 142px;
    }

  }

  .pcoded-header .navbar-nav > li:last-child {
    padding-right: 35px;
  }

}

.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}
.img-preview {
  overflow: hidden;
}

.zoom-container {
  position: relative;
  display: flex;
}

.img-preview-section-container {
  display: none;
}
.zoom-container:hover .img-preview-section-container {
  display: block;
  position: relative;
  left: 50px;
}
.zoom-container:hover .img-preview-section-container {
  left: 100%;
  position: absolute;
  background: #fff;
  border: 1px solid $theme-border;
}
.carousel-inner {
  overflow: visible;
  z-index: 1050;
}
@media only screen and (max-width: 991px){
  .pcoded-header, .pcoded-main-container {
      margin-left: 0;
      width: 100%;
  }
}
.card-header .card-header-right{
  .dropdown-menu.show {
    transform: none !important;
    right: 0 !important;
    top: 100% !important;
    left: auto !important;
  }
}

header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed {
  background: $theme-background;
}

header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed .b-brand .b-title {
  color: $menu-light-text-color;
}


header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed .mobile-menu span,
header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed .mobile-menu span:after,
header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed .mobile-menu span:before {
  background-color: $menu-light-text-color;
}

header.navbar.pcoded-header.navbar-expand-lg.header-default .mobile-menu.on span{
  background-color: transparent;
}

body.datta-rtl .pcoded-navbar.theme-horizontal ~ .pcoded-header {
  margin-right: auto;
}

body.datta-rtl  .pcoded-navbar.theme-horizontal ~ .pcoded-main-container {
  margin-right: auto;
}

body.datta-rtl .pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li > a:after {
  left: 0;
}

body.datta-rtl .pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li.active:after, .pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li.pcoded-trigger:after {
  right: 18px;;
}

.theme-horizontal .sidenav-horizontal:after,
.theme-horizontal .sidenav-horizontal:before {
  content: "";
  background: $header-dark-background;
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 5;
}

.theme-horizontal.navbar-blue .sidenav-horizontal:after,
.theme-horizontal.navbar-blue .sidenav-horizontal:before {
  background: $primary-color;
}

.theme-horizontal.navbar-red .sidenav-horizontal:after,
.theme-horizontal.navbar-red .sidenav-horizontal:before {
  background: $danger-color;
}

.theme-horizontal.navbar-purple .sidenav-horizontal:after,
.theme-horizontal.navbar-purple .sidenav-horizontal:before {
  background: $purple-color;
}

.theme-horizontal.navbar-lightblue .sidenav-horizontal:after,
.theme-horizontal.navbar-lightblue .sidenav-horizontal:before {
  background: $layout-6-bg-color;
}

.theme-horizontal.navbar-dark .sidenav-horizontal:after,
.theme-horizontal.navbar-dark .sidenav-horizontal:before {
  background: $dark-color;
}

.theme-horizontal .sidenav-horizontal:before {
  left: 100%;
}

.theme-horizontal .sidenav-horizontal:after {
  right: 100%;
}

@media only screen and (max-width: 991px){
  .pcoded-navbar{
    z-index: 1032;
  }

  body.datta-rtl {
    .pcoded-header, .pcoded-main-container{
      margin-right: 0;
    }
  }

  header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed {
    background: $header-dark-background;
  }

  header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed .b-brand .b-title {
    color: $header-dark-text-color;
  }


  header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed .mobile-menu span,
  header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed .mobile-menu span:after,
  header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed .mobile-menu span:before {
    background-color: $header-dark-text-color;
  }

  header.navbar.pcoded-header.navbar-expand-lg.header-default .mobile-menu.on span{
    background-color: transparent;
  }

  body.datta-dark .pcoded-header.header-dark .input-group .input-group-text, .pcoded-header.header-dark a, .pcoded-header.header-dark .btn, .pcoded-header.header-dark .dropdown-toggle {
    color: $dark-layout-font;
  }
}
.to-do-list .checkbox-fade .cr{
  float: left;
  border-radius: 3px;
  width: 22px;
  height: 22px;
}
.to-do-list .checkbox-fade label input[type=checkbox]:checked + .cr {
  background: #1dd5d2;
}

.user-activity .tab-content {
  padding-top: 5px;
}

.rodal-dialog {
  height: auto !important;
  bottom: auto !important;
  top: 50% !important;
  transform: translateY(-50%);
  .card{
    box-shadow: none;
    margin-bottom: 0px;
    .card-footer{
      padding-bottom: 0px;
    }
  }
}

.carousel-inner{
  overflow: visible;
  z-index: 1050;
}
.form-control[type="file"]{
  height: auto;
  padding-left: 0.45rem;
}
.table{
  .badge{
    margin-right: 0.25rem;
  }
}
.mb-3.row{
  >.col,
  .col.d-flex{
    @media (max-width:768px) {
      margin-top: 5px;
      margin-bottom: 5px;
      &:nth-child(2){
        flex-direction: column;
        text-align: left;
        > span{
          justify-content: flex-start !important;
          .form-control{
            max-width: 150px;
          }
        }
        .btn{
          margin-top: 8px;
          max-width: fit-content;
          margin-left: 0 !important;
        }
      }
    }
  }
}

.row.justify-content-between > .col > .d-flex.align-items-center strong{
  margin: 0 5px;
}

@media (max-width:575px) {
  .row.justify-content-between >*[class*="col-"],
  .row.justify-content-between >.col{
    flex: 100%;
    .pagination{
      justify-content: flex-start !important;

    }
    &:first-child{
      margin-bottom: 8px;
    }
    >.d-flex.align-items-center{
      input{
        width: 40px !important;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}
td{
  img.wid-40{
    max-width: 40px;
  }
}
.new-cust-card{
  @media (max-width:1024px) {
    .d-inline-block{
      width: calc(100% - 55px);
    }
    .align-middle .status{
      position: relative;
      top: 0;
      left: 0;
    }
  }
}
.table th, .table td{
  vertical-align: middle;
}
.cover-img-block{
  overflow: hidden;
  z-index: 1;
}
.user-about-block{
  position: relative;
  z-index: 5;
}
@media only screen and (max-width: 1200px){
  .user-card .user-about-block {
    margin-top: -10px;
  }
}

.alert{
  .float-right{
    margin-left: 8px;
    float: none !important;
  }
}
@media (max-width:768px){
  .modal-open .modal{
    padding-left: 0 !important;
  }
}
.carousel.slide{
  overflow: hidden;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow{
  left: 18px !important;
}
.smooth-dnd-container.horizontal{
  button{
    border: none;
  }
}
.ui-pnotify-icon{
  >span{
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 21px;
    height: 21px;
  }
}

.table.table-bordered.table-hover{
  th{
    select.form-control{
      min-width: 140px;
    }
  }
}
.filter-bar .navbar{
  @media (max-width:690px) {
    padding: 0.5rem 1rem 1rem;
    // #dropdown-basic{
    //   padding-left: 10px;
    // }
    .f-view{
      padding-left: 25px;
      margin-top: 8px;
    }
  }
}


.pcoded-header.header-default {
  background: $header-light-background;
  color: #000;
}
.pcoded-header.header-default .navbar-nav > li > .dropdown > .dropdown-toggle {
  color: #000 !important;
}
.pcoded-header.header-default .dropdown .profile-notification .pro-body li a {
  color: #000 !important;
}
